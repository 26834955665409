import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
// import banner1 from "../../images/contactus/star-rattings.png"
import icon1 from "../../images/contactus/Email.png"
import icon2 from "../../images/contactus/Whatsapp.png"
import PhoneInput from 'react-phone-number-input'
import { MDBInput, MDBBtn } from 'mdbreact';
import BookingSec from './BookingSec'


const ContactForm = () => {
  const [value, setValue] = useState()
  const [whatsappLink, setWhatsappLink] = useState('');
  const [whatsappwebLink, setWhatsappwebLink] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
      const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
      setWhatsappLink(link);
    }
    if (typeof window !== 'undefined') {
      const currentwebUrl = window.location.href;
      const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
      const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
      setWhatsappwebLink(link);
    }
  }, []);
  return (
    <section className="contactus getin bgremove">
      <div className="container">
        <div className="row table-content">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
          <h1 className="heading-h2 text-center">Contact Us</h1>
          
            {/* <h2 className="heading-h2 mb-4" >Get an Instant <span className="t-blue">Free </span><br />Demo Now </h2> */}
            <p className="pharagraph text-center">Complete the form to discuss your project with us in a private conversation. We’ll get back to you shortly!</p>
              <div className="just-text mt-3 mb-3">
              <div className="jst">
                <div className="txt-img">
                  <img width="18px" height="18px" src={icon1} alt="email" />
                </div>
                <div className="jst-cnt">
                  <p className="sbold">Email</p>
                  <p className="sbold"><a href="mailto:hello@coinsclone.com">hello@coinsclone.com</a></p>
                </div>
              </div>
              <div className="txt">
                <div className="txt-img">
                  <img width="18px" height="18px" src={icon2} alt="whatsapp" />
                </div>
                <div className="txt-cnt">
                  <p className="sbold">Whatsapp</p>
                  <p className="sbold">
                  <a href={whatsappwebLink} className="mobile-hide" rel="noreferrer" target="_blank">+91 9500575285</a>
                  <a href={whatsappLink} className="mobile-on" rel="noreferrer" target="_blank">+91 9500575285</a>
                    </p>
                </div>
              </div>
            </div>
            {/* <div className="review">
              <p className='h4'>We're happy to share our Online Rating & Reviews!</p>
              <span><img width="82px" height="15px" className="reviewicn" src={banner1} alt="rating" /></span>
              <span className="rated">Rated 4.5 / 5 based on 583 Overall Online Reviews.</span>
            </div>
            <div className="just-text">
              <div className="jst">
                <div className="txt-img">
                  <img width="18px" height="18px" src={icon1} alt="email" />
                </div>
                <div className="jst-cnt">
                  <p className="sbold">Email</p>
                  <p className="sbold"><a href="mailto:hello@coinsclone.com">hello@coinsclone.com</a></p>
                </div>
              </div>
              <div className="txt">
                <div className="txt-img">
                  <img width="18px" height="18px" src={icon2} alt="whatsapp" />
                </div>
                <div className="txt-cnt">
                  <p className="sbold">Whatsapp </p>
                  <p className="sbold">
                  <a href={whatsappwebLink} className="mobile-hide" rel="noreferrer" target="_blank">+91 9500575285</a>
                  <a href={whatsappLink} className="mobile-on" rel="noreferrer" target="_blank">+91 9500575285</a>
                    </p>
                </div>
              </div>
            </div>
            <div className="just-text">
              <div className="jst">
                <div className="txt-img">
                  <img width="18px" height="18px" src="https://coinsclone.mo.cloudinary.net/images/Telegram.svg" alt="telegram" />
                </div>
                <div className="jst-cnt">
                  <p className="sbold">Telegram</p>
                  <p className="sbold"><a href="https://t.me/Coinzclone">@Coinzclone</a></p>
                </div>
              </div>
              <div className="jst">
                <div className="txt-img">
                  <img width="18px" height="18px" src="https://coinsclone.mo.cloudinary.net/images/skypeicon.svg" alt="skype" />
                </div>
                <div className="jst-cnt">
                  <p className="sbold">Skype</p>
                  <p className="sbold"><a href="skype:live:hello_20214?chat">live:hello_20214</a></p>
                </div>
              </div>
            </div> */}
            <div className="formbox">
              <div className="login-form">
                <form  method="post" action="/mail.php" autoComplete="off">
                  <input className="form-control frmhidden" name="title" />
                  <br style={{ clear: 'both' }} />
                  <div className="form-group">
                    <MDBInput  label="Your Name" id="name" name="name" placeholder="Your Name" minLength={2} required />
                  </div>
                  <div className="form-group">
                    <MDBInput type="email" label="Your Email" id="emailf" name="email" placeholder="Your Email" required />
                  </div>
                  <MDBInput className="mobileNumber">
                    <PhoneInput
                      name="value" id="value"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      value={value}
                      onChange={setValue} />
                  </MDBInput>
                  <div className="form-group">
                    <MDBInput  name="skype" label="Skype / Telegram / Whatsapp" placeholder="Skype / Telegram / Whatsapp" />
                  </div>
                  <div className="form-group">
                    <MDBInput name="message" id="message" type="textarea" rows="3" cols="40" label="message" required defaultValue={""} />
                  </div>
                  <div className="text-right">
                    <MDBBtn type="submit" role="button" name="contact" className="btn bluebtn" defaultValue="Submit">Submit</MDBBtn>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12" >
            <BookingSec />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;